import { createRouter, createWebHistory } from "vue-router";
import EventPage from "@/views/EventPage.vue";
import EditEvent from "@/views/admin/EditEvent.vue";
import Gifts from "@/views/admin/Gifts.vue";
import Payouts from "@/views/admin/Payouts.vue";
import Contributions from "@/views/admin/Contributions.vue";
import ThankYouEmails from "@/views/admin/ThankYouEmails.vue";
import AmazonCheckout from "@/views/admin/AmazonCheckout.vue";
import Settings from "@/views/admin/Settings.vue";
import Dashboard from "@/views/admin/Dashboard.vue";
import Login from "@/views/Login.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import Register from "@/views/Register.vue";
import error from "@/views/404.vue";
import Declined from "@/views/Declined.vue";
import GoogleAuth from "@/views/GoogleAuth.vue";
import FBAuth from "@/views/FBAuth.vue";
import BrowserLoggedIn from "@/views/admin/BrowserLoggedIn.vue";
import { useUserStore } from "@/stores/UserStore";
import PasswordReset from "@/views/PasswordReset.vue";
import VerifyEmail from "@/views/VerifyEmail.vue";
import amazon from "@/mixins/amazon";
import DashboardKiosk from "@/views/kiosk/Dashboard.vue";
import KioskInsights from "@/views/kiosk/Insights.vue";
import KioskEvent from "@/views/kiosk/SingleEvent.vue";
import PartnersLogin from "@/views/partners/Login.vue";
import PartnersWelcome from "@/views/partners/Welcome.vue";
import PartnersDashboard from "@/views/partners/Dashboard.vue";
import KioskPartner from "@/views/kiosk/SinglePartner.vue";
import Impersonate from "@/views/Impersonate.vue";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/register",
      name: "register",
      component: Register,
      props: { login: false },
      meta: { title: "Register" },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      props: { login: true },
      meta: { title: "Login" },
    },
    {
      path: "/forgot-password",
      name: "forgot",
      component: ForgotPassword,
      meta: { title: "Forgot Password" },
    },
    {
      path: "/404",
      name: "404",
      component: error,
      meta: { title: "Page not found" },
    },
    // if registry is declined due to fraud
    {
      path: "/registry-declined",
      name: "declined",
      component: Declined,
      meta: { title: "Registry Declined", requiresAuth: true },
    },
    {
      path: "/auth/google",
      name: "google",
      component: GoogleAuth,
    },
    {
      path: "/auth/browser",
      name: "browserLoggedIn",
      component: BrowserLoggedIn,
      meta: { requiresAuth: true, title: "Browser Logged In" },
    },
    {
      path: "/auth/fb",
      name: "facebook",
      component: FBAuth,
    },
    // auth routes
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      meta: { requiresAuth: true, title: "Dashboard" },
    },
    {
      path: "/registry",
      name: "registry",
      component: EditEvent,
      meta: { requiresAuth: true, title: "Customize Registry" },
    },
    {
      path: "/gifts",
      name: "gifts",
      component: Gifts,
      meta: { requiresAuth: true, title: "Gifts" },
    },
    {
      path: "/contributions",
      name: "contributions",
      component: Contributions,
      meta: { requiresAuth: true, title: "Contributions" },
    },
    {
      path: "/payouts",
      name: "payouts",
      component: Payouts,
      meta: { requiresAuth: true, title: "Payouts" },
    },
    {
      path: "/thank-you-emails",
      name: "thank-you",
      component: ThankYouEmails,
      meta: { requiresAuth: true, title: "Thank You Emails" },
    },
    {
      path: "/amazon-checkout",
      name: "amazon-checkout",
      component: AmazonCheckout,
      meta: { requiresAuth: true, title: "Amazon Checkout" },
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: { requiresAuth: true, title: "Settings" },
    },
    {
      path: "/preview",
      name: "preview",
      component: EventPage,
      meta: { requiresAuth: true, title: "Preview" },
      props: { preview: true },
    },
    {
      path: "/preview/:giftId",
      name: "gift-preview",
      component: () => import("@/views/Gift.vue"),
      meta: { requiresAuth: true, title: "Preview" },
      props: { preview: true },
    },
    {
      path: "/password/reset/:uid?/:token?",
      name: "password-reset",
      component: PasswordReset,
      props: { uid: true, token: true },
    },
    {
      path: "/auth/verify-email/:token?",
      name: "verify-email",
      component: VerifyEmail,
      props: { token: true },
    },

    //kiosk routes
    {
      path: "/kiosk",
      name: "kiosk-home",
      component: DashboardKiosk,
      meta: { requiresAuth: true, title: "Kiosk" },
    },
    {
      path: "/kiosk/event/:eventId",
      name: "kiosk-event",
      component: KioskEvent,
      meta: { requiresAuth: true },
    },
    {
      path: "/kiosk/insights",
      name: "kiosk-insights",
      component: KioskInsights,
      meta: { requiresAuth: true, title: "Kiosk" },
    },
    {
      path: "/kiosk/partner/:partnerUsername",
      name: "kiosk-partner",
      component: KioskPartner,
      meta: { requiresAuth: true },
    },
    {
      path: "/auth/impersonate",
      name: "impersonate",
      component: Impersonate,
      meta: { title: "Impersonate" },
    },

    //routes for affiliate program
    {
      path: "/partners/login",
      name: "partners-login",
      component: PartnersLogin,
    },
    {
      path: "/partners/welcome:token?",
      name: "partners-welcome",
      component: PartnersWelcome,
    },
    {
      path: "/partners/dashboard",
      name: "partners-dashboard",
      component: PartnersDashboard,
      meta: { requiresAffiliateAuth: true },
    },
    //redirect to login if not found
    { path: "/:pathMatch(.*)*", component: error },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // Required because our anchor isn't available immediately
      // So elements are mounted after a delay
      tryScrollToAnchor(to.hash, 1000, 100);
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach(async (to, from) => {
  const userStore = useUserStore();

  if (
    // make sure the user is authenticated
    !userStore.isAuthenticated &&
    to.meta.requiresAuth &&
    to.name !== "login" &&
    from.name !== "register"
  ) {
    // redirect the user to the login page
    return { name: "login" };
  }
  // prevent user going to login or register once logged in (and affiliate pages)
  if (
    userStore.isAuthenticated &&
    [
      "login",
      "register",
      "partners-login",
      "partners-welcome",
      "partners-dashboard",
    ].includes(to.name)
  ) {
    // redirect the user to the login page
    return { name: "dashboard" };
  }
  if (
    to.name == "amazon-checkout" &&
    !(userStore.user.country_code in amazon.data().amazonLocale)
  ) {
    return { name: "dashboard" };
  }

  // guard kiosk routes
  if (
    userStore.isAuthenticated &&
    !userStore.user.is_admin &&
    to.path.includes("kiosk")
  ) {
    return { name: "dashboard" };
  }

  //affiliate guarding
  if (
    // make sure the user is authenticated
    !userStore.isAffiliateAuthenticated &&
    to.meta.requiresAffiliateAuth &&
    to.name !== "partners-login" &&
    from.name !== "partners-welcome"
  ) {
    // redirect the user to the login page
    return { name: "partners-login" };
  }

  if (userStore.isAffiliateAuthenticated && to.name != "partners-dashboard") {
    // redirect the user to the login page
    return { name: "partners-dashboard" };
  }
});

router.afterEach((to, from, next) => {
  document.title =
    "meta" in to && "title" in to.meta ? `${to.meta.title} | Reggie` : "Reggie";
});

// since we have to wait for the page to get the event data, the anchor isn't available immediately so using this function to retry till the anchor is available; https://stackoverflow.com/a/67812331/22307538
function wait(duration) {
  return new Promise((resolve) => setTimeout(resolve, duration));
}

async function tryScrollToAnchor(hash, timeout = 1000, delay = 100) {
  if (!hash.includes("#state")) {
    while (timeout > 0) {
      const el = document.querySelector(hash);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        break;
      }
      await wait(delay);
      timeout = timeout - delay;
    }
  }
}

export default router;
