<template>
  <div
    class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow"
  >
    <div v-if="this.$slots.heading" class="px-4 py-5 sm:px-6">
      <slot name="heading"></slot>
    </div>
    <div class="px-4 py-5 sm:p-6">
      <slot></slot>
    </div>
  </div>
</template>