<template>
  <div class="pricing-page">
    <vue-final-modal
      v-if="price"
      v-slot="{ close }"
      v-bind="$attrs"
      :z-index-base="100"
      classes="flex justify-center items-center"
      content-class="relative flex flex-col  max-h-[90vh] overflow-auto mx-4 p-2 border rounded-lg bg-secondary"
      @closed="if (paymentSuccess) $emit('cancel', close);"
    >
      <div class="relative bg-accent-400 lg:w-[70vw]">
        <el-button
          @click="$emit('cancel', close)"
          type="primary"
          size="large"
          plain
          class="absolute right-1 top-1"
          ><el-icon :size="20"><Close /></el-icon
        ></el-button>

        <div class="pt-12 sm:pt-16 lg:pt-20">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="text-center">
              <h2 class="font-heading text-3xl sm:text-4xl lg:text-5xl">
                Ready to take the next step?
              </h2>
              <p class="mt-4 font-subheading text-2xl text-gray-600">
                Upgrade your account to share your registry with your guests.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-8 bg-secondary pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
          <div class="relative">
            <div class="absolute inset-0 h-1/2 bg-accent-400" />
            <div class="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div
                class="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none"
              >
                <div class="flex-1 bg-secondary px-6 py-8 lg:p-12">
                  <div class="flex items-center space-x-4">
                    <h3 class="font-heading text-2xl text-primary sm:text-3xl">
                      Reggie Paid Plan
                    </h3>
                    <el-tag v-if="event.discount" size="large" type="success"
                      >{{ event.discount }}% Discount</el-tag
                    >
                  </div>
                  <div class="mt-8">
                    <div v-if="!paymentSuccess" class="flex items-center">
                      <h4
                        class="flex-shrink-0 bg-secondary pr-4 text-base font-semibold"
                      >
                        What's included
                      </h4>
                      <div class="flex-1 border-t-2 border-gray-200" />
                    </div>
                    <ul
                      v-if="!paymentSuccess"
                      role="list"
                      class="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0"
                    >
                      <li
                        v-for="feature in includedFeatures"
                        :key="feature"
                        class="flex items-start lg:col-span-1"
                      >
                        <div class="flex-shrink-0">
                          <CheckCircleIcon
                            class="h-5 w-5 text-primary"
                            aria-hidden="true"
                          />
                        </div>
                        <p class="ml-3 text-sm text-gray-700">{{ feature }}</p>
                      </li>
                    </ul>

                    <transition
                      v-show="stripeObject.showStripeForm && !paymentSuccess"
                      enter-active-class="transform ease-in duration-400 transition"
                      enter-from-class="opacity-0"
                      enter-to-class=" opacity-100"
                      leave-active-class="transition ease-in duration-400"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <div>
                        <form @submit.prevent id="payment-form" class="mt-10">
                          <div id="payment-element" class="mb-6"></div>
                          <el-button
                            :loading="loading"
                            size="large"
                            type="primary"
                            plain
                            @click="confirmStripePayment"
                            class="w-full"
                            ><span class="text-lg">Pay Now</span></el-button
                          >
                          <div v-if="stripeObject.errorMessage" class="mt-2">
                            <el-alert
                              :title="stripeObject.errorMessage"
                              type="error"
                              effect="dark"
                              :closable="false"
                            />
                          </div>
                        </form>
                      </div>
                    </transition>
                    <div v-if="paymentSuccess">
                      <el-alert
                        title="Payment Successful!"
                        type="success"
                        :closable="false"
                      />
                      <div class="mt-5 rounded-md bg-accent-300 p-4">
                        <div class="flex items-center">
                          <div class="w-4/5">
                            <p class="text-sm font-medium text-black">
                              In order for us to transfer your guests'
                              contributions to your bank account, you will need
                              to verify some information with our payment
                              provider
                              <a
                                class="underline"
                                target="_blank"
                                href="https://stripe.com"
                                >Stripe</a
                              >.
                            </p>
                            <p class="mt-3 text-xs text-gray-500">
                              If you prefer to do this later, you can click the
                              verify details button from your dashboard.
                            </p>
                          </div>
                          <div class="ml-5 w-1/5">
                            <VerifyStripe :event="event" page="payment" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="border-t border-primary bg-secondary px-6 py-8 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:border-l lg:border-t-0 lg:p-12"
                >
                  <!-- <p class="text-lg font-medium leading-6 text-gray-900">
                  Pay once, own it forever
                </p> -->
                  <div
                    v-if="!paymentSuccess"
                    class="mt-4 flex items-end justify-center text-5xl font-bold tracking-tight text-gray-900"
                  >
                    <div
                      v-if="event.discount > 0"
                      class="flex flex-col items-end"
                    >
                      <div class="flex space-x-2">
                        <span class="text-4xl line-through opacity-50">{{
                          formatCurrency(
                            price.amount / 100,
                            price.country_code,
                            price.currency
                          )
                        }}</span>
                      </div>
                      <span class="">{{
                        formatCurrency(
                          (price.amount * ((100 - event.discount) / 100)) / 100,
                          price.country_code,
                          price.currency
                        )
                      }}</span>
                    </div>
                    <div v-else>
                      <span>{{
                        formatCurrency(
                          price.amount / 100,
                          price.country_code,
                          price.currency
                        )
                      }}</span>
                    </div>
                    <span
                      class="ml-3 text-xl font-medium tracking-normal text-gray-500"
                      >{{ price.currency.toUpperCase() }}</span
                    >
                  </div>
                  <!-- <p class="mt-4 text-sm">
                  <a href="#" class="font-medium text-gray-500 underline"
                    >Learn about our membership policy</a
                  >
                </p> -->
                  <div class="mt-6">
                    <el-button
                      v-if="!paymentSuccess"
                      :loading="loading"
                      :disabled="stripeObject.showStripeForm"
                      @click="loadStripeForm()"
                      type="primary"
                      size="large"
                      plain
                      ><span class="text-lg">Upgrade</span></el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/20/solid";
import { Close } from "@element-plus/icons-vue";
import VerifyStripe from "@/components/admin/VerifyStripe.vue";
import { ElAlert } from "element-plus";

export default {
  components: { CheckCircleIcon, VerifyStripe, Close },
  name: "PricingPage",
  inheritAttrs: false,
  emits: ["cancel", "get-event"],
  props: ["event", "price"],

  data() {
    return {
      includedFeatures: [
        "Add unlimited gifts to your registry",
        "Unlimited guest contributions",
        "Contributions from anywhere in the world",
        "Your own beautiful wedding page",
        "Beautiful customizable templates",
        "Send thank you emails to your guests",
      ],
      paymentSuccess: false,
      loading: false,
      stripeObject: {
        showStripeForm: null,
        stripe: null,
        elements: null,
        errorMessage: null,
      },
    };
  },
  methods: {
    loadStripeForm() {
      this.loading = true;
      this.$axiosAuth
        .post("host/stripe/pay")
        .then((response) => {
          let clientSecret = response.data.clientSecret;
          this.stripeObject.stripe = Stripe(
            import.meta.env.VITE_STRIPE_PUBLIC_KEY
          );
          this.stripeObject.elements = this.stripeObject.stripe.elements({
            clientSecret,
          });
          const paymentElement = this.stripeObject.elements.create("payment");
          paymentElement.mount("#payment-element");
          this.stripeObject.showStripeForm = true;
          this.loading = false;
        })
        .catch((err) => {});
    },
    confirmStripePayment() {
      this.loading = true;
      this.stripeObject.stripe
        .confirmPayment({
          elements: this.stripeObject.elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: import.meta.env.VITE_APP_URL + "/home",
          },
          redirect: "if_required",
        })
        .then((result) => {
          this.loading = false;
          this.stripeObject.errorMessage = null;
          if (result.error) {
            let error = result.error;
            this.stripeObject.errorMessage = error.message;
            if (
              error.type === "card_error" ||
              error.type === "validation_error"
            ) {
              ElNotification({
                title: "Error",
                message: "Something went wrong.",
                type: "error",
              });
            }
            if (error.decline_code === "fraudulent") {
              setTimeout(() => {
                //reload page
                window.location.reload();
              }, 3000);
            }
          }
          if (result.paymentIntent && result.paymentIntent.status) {
            this.stripeObject.showStripeForm = false;
            ElNotification({
              title: "Success",
              message: "Payment successful",
              type: "success",
            });
            this.$confetti.start();
            setTimeout(() => {
              this.$confetti.stop();
            }, 3000);
            this.paymentSuccess = true;
            this.$emit("get-event");
          }
        });
    },
  },
};
</script>

<style>
.pricing-page {
  .el-tag {
    @apply border border-[#a8f170] bg-[#D1FAB3]	!important;
  }
  .el-tag__content {
    @apply text-sm font-bold text-[#228403] !important;
  }
}
</style>
