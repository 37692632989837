<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-background="#ffffff"
  ></div>
  <AdminLayout v-if="!loading">
    <div class="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
      <OverviewHeaderPartner :partner="partner" />
    </div>
  </AdminLayout>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";
import OverviewHeaderPartner from "../../components/kiosk/OverviewHeaderPartner.vue";

export default {
  components: {
    AdminLayout,
    OverviewHeaderPartner,
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      loading: true,
      partner: null,
    };
  },

  methods: {
    getData() {
      this.$axiosAuth
        .get(`/kiosk/partners/${this.$route.params.partnerUsername}`)
        .then((res) => {
          this.partner = res.data;
          this.loading = false;
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Error getting data",
            type: "error",
          });
        });
    },
  },
};
</script>

<style>
.el-timeline {
  li:last-of-type {
    padding-bottom: 0px !important;
  }
}

.preview-dialog {
  .el-dialog {
    padding: 0;
    .show-close {
      display: none;
    }
    .el-dialog__body {
      padding: 0 !important;
    }
  }
}
</style>
