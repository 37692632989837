<template>
  <main class="min-h-screen bg-cover bg-center">
    <div
      class="flex min-h-screen flex-col items-center justify-center bg-black/70"
    >
      <div
        class="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48"
      >
        <h1
          class="mt-2 text-4xl font-bold tracking-tight text-red-400 sm:text-5xl"
        >
          Due to security reasons, your registry has been declined.
        </h1>
        <p class="text-md mt-2 font-medium text-white md:text-xl">
          If you believe this is an error please
          <a href="mailto:help@lovereggie.com">contact us</a>.
        </p>
        <div class="mt-6">
          <el-button @click="logout()" type="primary" size="large" plain
            >Logout</el-button
          >
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";
export default {
  methods: {
    ...mapActions(useUserStore, ["authenticateUser"]),
    logout() {
      this.$axiosAuth.post(`users/logout/`).then((res) => {
        this.authenticateUser(false, null);
        this.$router.go();
      });
    },
  },
};
</script>
