<template>
  <main class="flex min-h-screen items-center justify-center bg-orange-50">
    <div class="text-2xl"></div>
  </main>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";
import { ElNotification } from "element-plus";
import { mapActions } from "pinia";
export default {
  created() {
    // check if route has token if not push login
    const token = this.$route.query?.token;
    if (!token) {
      this.$router.push({ name: "login" });
    }
  },
  mounted() {
    // get token from the URL and call impersonateLogin from a private window
    const token = this.$route.query.token;
    if (token) {
      this.impersonateLogin(token);
    }
  },
  data() {
    return {
      loading: false,
      user: {},
    };
  },
  methods: {
    ...mapActions(useUserStore, ["authenticateUser", "authenticateAffiliate"]),
    impersonateLogin(token) {
      this.$axiosAuth
        .post("kiosk/impersonate/login/", {
          impersonation_id: token,
        })
        .then((response) => {
          let user = {};
          if (response.data.hasOwnProperty("affiliate_code")) {
            user.email = response.data.user.email;
            user.name = response.data.name;
            user.affiliateCode = response.data.affiliate_code;
            user.is_impersonated = true;
            this.authenticateAffiliate(true, user);
            this.$router.push({ name: "partners-dashboard" });
          } else {
            user.email = response.data.user.email;
            user.pk = response.data.pk;
            user.username = response.data.username;
            user.email_verified = response.data.email_verified;
            user.start_datetime = response.data.start_datetime;
            user.host_name1 = response.data.host_name1;
            user.host_name2 = response.data.host_name2;
            user.country_code = response.data.country_code;
            user.is_impersonated = true;
            if (response.data.hasOwnProperty("is_admin")) {
              user.is_admin = response.data.is_admin;
            }

            this.authenticateUser(true, user);
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch((error) => {
          ElNotification({
            title: "Error",
            message: error.response.data.detail,
            type: "error",
          });
          this.$router.push({ name: "login" });
        });
    },
  },
};
</script>
