<template>
  <div class="onboarding-header bg-white">
    <div
      class="px-4 py-6 sm:px-6 lg:mx-auto lg:border lg:border-gray-200 lg:px-40"
    >
      <!-- <div class="support-warning mb-4">
        <el-alert
          v-if="event && event.has_been_live"
          type="warning"
          :closable="false"
          show-icon
          effect="dark"
        >
          Reggie is currently aware of an issue impacting some users'
          registries. We are actively working on this and will let you know as
          soon as it's resolved.
        </el-alert>
      </div> -->

      <div class="w-full md:flex md:items-center md:justify-between">
        <div>
          <!-- Profile -->
          <OnboardingList
            @show-pricing-modal="showPricingModal()"
            @show-not-yet-paid-modal="showNotYetPaidModal = true"
            v-if="
              (event && !event.is_paid) ||
              event.stripe_registration_status != 'complete'
            "
            :event="event"
          />
          <div v-else>
            <div class="flex items-center">
              <!-- <img
                v-if="event"
                class="h-16 w-16 rounded-full object-cover sm:block"
                :src="event[event.properties.img_source]"
                alt=""
              /> -->
              <div class="ml-3">
                <h1
                  class="text-2xl font-bold leading-7 text-black sm:truncate sm:leading-9"
                >
                  {{ `Hey, ${event.name_host1} & ${event.name_host2}.` }}
                </h1>
                <p class="text-red-700">
                  {{ `The big day ${daysTill(event.start_datetime, true)}.` }}
                </p>
                <div
                  v-if="$dayjs().isAfter(event.start_datetime)"
                  class="mt-2 max-w-[65ch]"
                >
                  You're married, yahoo! Once you are ready, you can send your
                  guests a
                  <router-link
                    :to="{ name: 'thank-you' }"
                    class="border-b border-red-300"
                  >
                    thank you email</router-link
                  >.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="buttons mt-4 flex flex-col gap-2 md:mt-0 md:flex-row">
          <VerifyStripe v-if="event.is_paid" :event="event" />
          <!-- using <a> to force refresh so AOS lib works -->
          <a v-if="!event.is_live" href="/preview">
            <el-button type="primary" size="large" round plain>
              <span class="text-lg"> Preview Registry </span>
            </el-button>
          </a>

          <a
            v-else
            :href="`${marketingUrl}/${event.domain_name}`"
            target="_blank"
          >
            <el-button type="primary" size="large" round plain>
              <span class="text-lg"> See Live Registry </span>
            </el-button></a
          >
          <el-tooltip
            v-if="!event.is_live"
            :disabled="
              !(
                !event.is_paid ||
                !event.email_verified ||
                event.stripe_registration_status != 'complete'
              )
            "
            class="box-item"
            effect="dark"
            content="You need to upgrade to our paid plan and verify your bank details to go live."
            placement="top"
          >
            <span>
              <el-button
                :disabled="
                  !event.is_paid ||
                  !event.email_verified ||
                  event.stripe_registration_status != 'complete'
                "
                @click="
                  !event.has_been_live
                    ? (showLiveModal = true)
                    : publishEvent(true)
                "
                type="primary"
                size="large"
                round
              >
                <span class="text-lg"> Go live </span>
              </el-button>
            </span>
          </el-tooltip>
          <el-button
            v-else
            @click="publishEvent(false)"
            type="primary"
            size="large"
            round
          >
            <span class="text-lg"> Unpublish Registry </span>
          </el-button>
        </div>
      </div>
    </div>

    <PricingPage
      :event="event"
      :price="modal.price"
      v-if="event && !event.is_paid"
      v-model="modal.show"
      @cancel="
        modal.show = false;
        this.$emit('refreshEvent');
      "
    />
  </div>
  <el-dialog
    v-model="showNotYetPaidModal"
    width="50%"
    title="Upgrade to paid plan"
  >
    Before you add your bank details, you will first need to upgrade to our paid
    plan.
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="showNotYetPaidModal = false">Back</el-button>
        <el-button
          type="primary"
          @click="(showNotYetPaidModal = false), showPricingModal()"
        >
          Next
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog v-model="showLiveModal" width="50%">
    <div class="space-y-5">
      <div>
        <h4 class="text-2xl font-bold">Hooray it's time to go live 🎉</h4>
        <p class="mt-1 text-base">Here are a few last things to check-off ✅</p>
      </div>
      <div class="space-y-6 rounded-md border border-gray-200 bg-gray-50 p-5">
        <div class="space-y-2">
          <p class="text-lg font-medium text-gray-900">
            Confirm your wedding date
          </p>
          <div class="w-56">
            <el-date-picker
              v-model="event.start_datetime"
              type="date"
              size="large"
              :clearable="false"
              placeholder="Pick a Date"
              :format="dateFormatCode"
              :value-format="dateValueFormatCode"
              :disabled-date="
                function (date) {
                  return date < Date.now();
                }
              "
              @change="updateEvent"
            />
          </div>
          <p class="font-semibold">
            Once you go live, you won't be able to change this.
          </p>
        </div>
        <div class="space-y-2">
          <p class="text-lg font-medium text-gray-900">
            Confirm payment settings
          </p>
          <el-checkbox
            v-model="event.stripe_fee"
            label="My guests pay for the Stripe fee"
            size="large"
            @change="updateEvent"
          />
          <el-alert type="error" :closable="false">
            <div class="text-sm leading-5">
              <p>
                Check this box to have your guests pay the domestic credit card
                fee instead of you.
              </p>
              <p>
                International credit cards will incur an additional cost that
                you will pay either way.
              </p>
              <p>You can change this any time in your settings.</p>
            </div></el-alert
          >
        </div>
      </div>
      <div>
        <h4 class="text-lg font-bold">You're all set.</h4>
        <p class="mt-1 text-base">
          Your registry will be live at
          <span class="font-semibold"
            >lovereggie.com/{{ event.domain_name }}</span
          >
        </p>
      </div>

      <div class="mt-3">
        <el-button
          @click="publishEvent(true)"
          size="large"
          type="primary"
          class="w-full"
          ><span class="text-lg">Go Live!</span></el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import OnboardingList from "@/components/admin/onboardingList.vue";
import PricingPage from "@/components/admin/PricingPage.vue";
import VerifyStripe from "@/components/admin/VerifyStripe.vue";

export default {
  components: { OnboardingList, PricingPage, VerifyStripe },
  props: ["event"],
  emits: ["refreshEvent"],
  data() {
    return {
      marketingUrl: import.meta.env.VITE_MARKETING_URL,
      loading: false,
      modal: {
        show: false,
        price: null,
      },
      showLiveModal: false,
      showNotYetPaidModal: false,
    };
  },
  // mounted() {
  //   if (this.$route.query.show && this.$route.query.show == "pay") {
  //     this.showPricingModal();
  //   }
  // },
  methods: {
    publishEvent(param) {
      let publish = param;
      this.$axiosAuth
        .patch(
          `host/event/${this.event.event_id}/`,
          {
            is_live: publish,
          },
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        )
        .then((res) => {
          publish
            ? ElNotification({
                title: "Success",
                message: "Registry is live",
                type: "success",
              })
            : ElNotification({
                title: "Success",
                message: "Registry hidden from guests",
                type: "success",
              });
        })
        .then(() => {
          setTimeout(() => {
            this.$emit("refreshEvent");
          }, 1000);
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong",
            type: "error",
          });
        });
      this.showLiveModal ? (this.showLiveModal = false) : null;
      this.event.has_been_live = true;
    },
    showPricingModal() {
      this.$axiosAuth
        .get("host/price/")
        .then((res) => {
          this.modal.price = res.data;
          this.modal.show = true;
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Error opening payment modal. Please contact support.",
            type: "error",
          });
        });
    },
    updateEvent() {
      this.$axiosAuth
        .patch(`host/event/${this.event.event_id}/`, {
          stripe_fee: this.event.stripe_fee,
          start_datetime: this.event.start_datetime,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Event settings updated successfully",
            type: "success",
          });
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Settings could not be updated",
            type: "error",
          });
        });
    },
  },
};
</script>

<style>
.onboarding-header {
  .buttons {
    button {
      @apply w-full;
    }
  }
}
.el-dialog__body {
  @apply px-7 pt-0 !important;
}

.el-checkbox {
  @apply mt-0 !important;
}

.support-warning {
  .el-alert__description {
    font-size: 17px !important;
  }
}
</style>
