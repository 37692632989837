<template>
  <main class="flex min-h-screen items-center justify-center bg-orange-50">
    <div class="text-2xl"></div>
  </main>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";
export default {
  mounted() {
    const access_token = this.getHashValue("access_token");
    if (!access_token) {
      this.$router.push({ name: "login" });
      return;
    }
    const eventFormEncoded = this.getHashValue("state");
    if (eventFormEncoded === "login-browser-extension") {
      this.authenticateLoginBrowserExtension(access_token);
    } else {
      // Close the pop-up window after obtaining the data
      window.close();

      const eventFormDecoded = JSON.parse(
        window.atob(decodeURIComponent(eventFormEncoded))
      );
      const authData = {
        access_token: access_token,
        eventFormDecoded: eventFormDecoded,
      };
      // Send the authentication data to the parent window
      window.opener.postMessage(authData, window.origin);
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(useUserStore, ["authenticateUser"]),
    getHashValue(key) {
      // Get the hash value from the URL
      var matches = window.location.hash.match(new RegExp(key + "=([^&]*)"));
      return matches ? matches[1] : null;
    },
    authenticateLoginBrowserExtension(access_token) {
      this.$axiosAuth
        .post("users/auth/google/?type=extension&action=login", {
          access_token: access_token,
        })
        .then((response) => {
          let user = {};
          user.email = response.data.user.email;
          user.pk = response.data.pk;
          user.username = response.data.username;
          user.email_verified = response.data.email_verified;
          user.start_datetime = response.data.start_datetime;
          user.host_name1 = response.data.host_name1;
          user.host_name2 = response.data.host_name2;

          this.authenticateUser(true, user);
          this.loading = false;
          this.$router.push({ name: "browserLoggedIn" });
        })
        .catch((error) => {});
    },
  },
};
</script>
