<template>
  <div>
    <div v-bind="getRootProps()">
      <input v-bind="getInputProps()" accept="image/png, image/jpeg" />
      <div class="mt-1 sm:col-span-2 sm:mt-0">
        <div
          class="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5"
          :class="[isDragActive ? 'opacity-50' : '']"
        >
          <div class="space-y-1 text-center">
            <svg
              class="mx-auto h-20 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="flex text-sm text-gray-600">
              <label
                for="file-upload"
                class="relative cursor-pointer rounded-md font-medium text-red-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-500 focus-within:ring-offset-2 hover:text-red-500"
              >
                <button>Click to add</button>
              </label>
              <p class="pl-1">or drag and drop</p>
            </div>
            <p class="text-xs text-gray-500">PNG or JPG up to 5MB</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useDropzone } from "vue3-dropzone";
import { ElNotification } from "element-plus";
import { v4 as uuidv4 } from "uuid"; // UUID library

export default {
  name: "UseDropzoneDemo",
  setup(props, context) {
    function onDropAccepted(acceptFiles) {
      const file = acceptFiles[0];

      // Check file type
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        ElNotification({
          title: "Error",
          message: "Invalid file type. Only JPG and PNG images are allowed.",
          type: "error",
        });
        return; // Exit early if invalid file type
      }

      // Check file size
      if (file.size > 5000000) {
        // 5MB size limit
        ElNotification({
          title: "Error",
          message:
            "Image is too big, try adding a smaller one or compressing it.",
          type: "error",
        });
        return; // Exit early if file is too large
      }

      // Get the file extension by splitting at the last period
      const fileExtension = file.name.split(".").pop(); // Get file extension (last part)

      // Ensure the extension is valid (jpg/png)
      if (!["jpg", "jpeg", "png"].includes(fileExtension.toLowerCase())) {
        ElNotification({
          title: "Error",
          message:
            "Invalid file format. Only PNG, JPG, and JPEG are supported.",
          type: "error",
        });
        return;
      }

      // Generate a random UUID for the file name and keep the original extension
      const newFileName = `${uuidv4()}.${fileExtension}`;

      // Create a new File object with the new name
      const renamedFile = new File([file], newFileName, { type: file.type });

      // Proceed with valid file
      context.emit("imageUploaded", renamedFile);
    }
    function onDropRejected(rejectFiles) {
      ElNotification({
        title: "Error",
        message: "Something went wrong, contact us for help.",
        type: "error",
      });
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({
      onDropAccepted,
      onDropRejected,
    });

    return {
      getRootProps,
      getInputProps,
      ...rest,
    };
  },
};
</script>
