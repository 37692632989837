<template>
  <div class="onboarding-header bg-white">
    <div
      class="px-4 py-6 sm:px-6 lg:mx-auto lg:border lg:border-gray-200 lg:px-40"
    >
      <div class="flex items-center gap-x-2">
        <ShieldCheckIcon class="h-12 w-12 text-red-400" aria-hidden="true" />
        <div>
          <h1
            class="text-2xl font-bold leading-7 text-black sm:truncate sm:leading-9"
          >
            We are currently reviewing your event.
          </h1>
          <p class="text-lg text-gray-700">
            In order to provide the most secure and reliable experience for you
            and your guests, we review events before they go live. Check back in
            24 hours for an update.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ShieldCheckIcon } from "@heroicons/vue/20/solid";

export default {
  components: {
    ShieldCheckIcon,
  },
};
</script>


