<template>
  <div v-if="user" class="fixed z-[100] w-full">
    <!-- Email verification banner -->
    <div
      v-if="
        (!user.email_verified || user.email_bounced || user.is_impersonated) &&
        this.$route.name != 'registry'
      "
      class="relative isolate flex items-center overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1"
    >
      <div
        class="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style="
            clip-path: polygon(
              74.8% 41.9%,
              97.2% 73.2%,
              100% 34.9%,
              92.5% 0.4%,
              87.5% 0%,
              75% 28.6%,
              58.5% 54.6%,
              50.1% 56.8%,
              46.9% 44%,
              48.3% 17.4%,
              24.7% 53.9%,
              0% 27.9%,
              11.9% 74.2%,
              24.9% 54.1%,
              68.6% 100%,
              74.8% 41.9%
            );
          "
        />
      </div>
      <div
        class="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        aria-hidden="true"
      >
        <div
          class="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style="
            clip-path: polygon(
              74.8% 41.9%,
              97.2% 73.2%,
              100% 34.9%,
              92.5% 0.4%,
              87.5% 0%,
              75% 28.6%,
              58.5% 54.6%,
              50.1% 56.8%,
              46.9% 44%,
              48.3% 17.4%,
              24.7% 53.9%,
              0% 27.9%,
              11.9% 74.2%,
              24.9% 54.1%,
              68.6% 100%,
              74.8% 41.9%
            );
          "
        />
      </div>
      <div
        v-if="user.email_bounced"
        class="flex items-center justify-between gap-x-2 gap-y-2"
      >
        <p class="text-sm font-bold lg:text-base">
          We could not reach your registered email address, please check it was
          entered correctly.
        </p>
        <router-link :to="{ name: 'settings', query: { tab: 'second' } }">
          <h3
            class="flex-none cursor-pointer rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Update Email <span aria-hidden="true">&rarr;</span>
          </h3>
        </router-link>
      </div>
      <div
        v-else-if="!user.email_verified"
        class="flex items-center justify-between gap-x-2 gap-y-2"
      >
        <p class="text-xs font-bold md:text-base">
          Email address not verified. Please check your inbox for a verification
          email.
        </p>
        <a
          @click="resendVerifyEmail()"
          class="flex-none cursor-pointer rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >Resend Email <span aria-hidden="true">&rarr;</span></a
        >
      </div>
      <div
        v-if="user.is_impersonated"
        class="flex items-center justify-between gap-x-2 gap-y-2"
      >
        <p class="text-xs font-bold md:text-base">
          You are currently impersonating user
          <span class="text-primary">{{ user.email }}</span
          >. Ensure you sign out or close this private browsing widow after use.
        </p>
      </div>
      <div class="flex flex-1 justify-end"></div>
    </div>

    <!-- Navbar -->
    <Disclosure
      as="nav"
      class="w-full shadow"
      v-slot="{ open }"
      :class="isKiosk ? 'bg-primary-100' : 'bg-white'"
    >
      <div class="mx-auto px-2 sm:px-6 lg:px-8">
        <div
          class="relative flex h-12 space-x-2 sm:justify-between lg:space-x-0"
        >
          <div class="flex items-center sm:hidden">
            <!-- Mobile menu button -->
            <DisclosureButton
              class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon
                v-if="!open"
                class="block h-6 w-6"
                aria-hidden="true"
              />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="flex flex-shrink-0 items-center">
            <router-link :to="{ name: 'dashboard' }">
              <h3 class="font-heading text-3xl text-primary">reggie</h3>
            </router-link>
          </div>
          <div class="hidden gap-x-4 sm:flex sm:items-center">
            <Menu
              v-for="(dropdown, index) in dropdowns"
              :key="index"
              as="div"
              class="dropdown-desktop-parent relative"
              :class="dropdown.order"
              v-slot="{ close }"
            >
              <div>
                <MenuButton
                  class="inline-flex items-center border-b-2 border-transparent text-sm font-medium capitalize text-gray-500 hover:text-gray-700"
                >
                  <span class="sr-only"
                    >Open {{ dropdown.label }} dropdown</span
                  >
                  {{ dropdown.label }}
                  <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem
                    v-for="link in links"
                    v-show="link.dropdown == dropdown.label && link.show"
                    :key="link.name"
                    v-slot="{ active, close }"
                  >
                    <router-link
                      class="dropdown-desktop-child rounded-md"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      :to="{ name: link.name }"
                      ><div @click="close">{{ link.label }}</div></router-link
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
            <router-link
              v-for="link in links"
              v-show="!link.dropdown && link.show"
              :key="link.name"
              :class="link.order"
              class="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:text-gray-700"
              :to="{ name: link.name }"
              >{{ link.label }}</router-link
            >
          </div>
          <!-- Profile section -->
          <div
            class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
          >
            <!-- <button
            type="button"
            class="
              rounded-full
              bg-white
              p-1
              text-gray-400
              hover:text-gray-500
              focus:outline-none
              focus:ring-2
              focus:ring-indigo-500
              focus:ring-offset-2
            "
          >
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button> -->

            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton
                  class="flex rounded-full text-sm"
                  :class="isKiosk ? 'bg-primary-100' : 'bg-white'"
                >
                  <span class="sr-only">Open user menu</span>
                  {{ `${user.host_name1} & ${user.host_name2}` }}
                  <!-- <img
                  class="h-8 w-8 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                /> -->
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem v-if="user.is_admin" v-slot="{ active }">
                    <router-link
                      :to="{ name: 'kiosk-home' }"
                      class="cursor-pointer"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >Kiosk</router-link
                    >
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a
                      @click="logout"
                      class="cursor-pointer"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      ]"
                      >Sign out</a
                    >
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>
      <!-- mobile menu -->
      <DisclosurePanel class="sm:hidden">
        <div class="space-y-1 pb-4 pt-2">
          <router-link
            v-for="link in links"
            v-show="link.show"
            :key="link.name"
            :to="{ name: link.name }"
            ><DisclosureButton
              as="span"
              class="nav-link-mobile block py-2 pl-3 pr-4 text-base font-medium"
              >{{ link.label }}</DisclosureButton
            ></router-link
          >
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script>
import { useUserStore } from "@/stores/UserStore";
import { mapStores } from "pinia";
import { mapActions } from "pinia";

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import amazon from "@/mixins/amazon.js";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Bars3Icon,
    BellIcon,
    XMarkIcon,
    ChevronDownIcon,
  },
  mixins: [amazon],
  data() {
    return {
      event: null,
    };
  },

  computed: {
    dropdowns() {
      let dropdowns = [];
      if (this.isKiosk) {
      } else {
        dropdowns = [
          { label: "registry", order: "order-2" },
          { label: "contributions", order: "order-5" },
          { label: "post wedding", order: "order-7" },
        ];
      }
      return dropdowns;
    },
    links() {
      let links = [];
      if (this.isKiosk) {
        links = [
          {
            name: "kiosk-home",
            label: "Dashboard",
            show: true,
          },
          {
            name: "kiosk-insights",
            label: "Insights",
            show: true,
          },
        ];
      } else {
        links = [
          {
            name: "dashboard",
            label: "Dashboard",
            order: "order-1",
            show: true,
          },
          {
            name: "registry",
            label: "Edit Design",
            dropdown: "registry",
            show: true,
          },
          {
            name: "gifts",
            label: "Manage Gifts",
            dropdown: "registry",
            show: true,
          },
          {
            name: "contributions",
            label: "Guest Contributions",
            dropdown: "contributions",
            order: "order-3",
            show: true,
          },
          {
            name: "payouts",
            label: "Payouts",
            dropdown: "contributions",
            order: "order-4",
            show: true,
          },

          {
            name: "thank-you",
            label: "Thank You Emails",
            dropdown: "post wedding",
            show: true,
          },
          {
            name: "amazon-checkout",
            label: "Amazon Gift Checkout",
            dropdown: "post wedding",
            show: this.user.country_code in this.amazonLocale ? true : false,
          },
          { name: "settings", label: "Settings", order: "order-6", show: true },
        ];
      }
      return links;
    },
    isKiosk() {
      return this.$route.path.includes("kiosk");
    },
    user() {
      const userStore = useUserStore();
      return userStore.user;
    },
  },

  methods: {
    resendVerifyEmail() {
      this.$axios
        .post("users/resend-verify-email/", { email: this.user.email })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Email sent.",
            type: "success",
          });
        })
        .catch((err) => {
          ElNotification({
            title: "Error",
            message: "Something went wrong.",
            type: "error",
          });
        });
    },

    ...mapActions(useUserStore, ["authenticateUser"]),

    logout() {
      this.$axiosAuth.post(`users/logout/`).then((res) => {
        this.authenticateUser(false, null);
        this.$router.go();
      });
    },
  },
};
</script>
