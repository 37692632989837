<template>
  <card>
    <el-timeline style="max-width: 600px">
      <el-timeline-item
        v-if="event.start_datetime"
        color="#E16345"
        :timestamp="$dayjs(event.start_datetime).format('YYYY-MM-DD')"
      >
        Wedding Date
      </el-timeline-item>
      <el-timeline-item
        v-if="event.paid_datetime"
        color="#0bbd87"
        :timestamp="$dayjs(event.paid_datetime).format('YYYY-MM-DD')"
      >
        Event Paid
      </el-timeline-item>
      <el-timeline-item
        v-if="event.created"
        :timestamp="$dayjs(event.created).format('YYYY-MM-DD')"
      >
        Event Created
      </el-timeline-item>
    </el-timeline>
  </card>
</template>

<script>
export default {
  props: ["event"],
};
</script>

<style>
</style>