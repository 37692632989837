<template>
  <card>
    <div class="flex items-center justify-between">
      <div class="flex items-center gap-x-4">
        Status
        <span
          :class="[
            colorEventStatusTags[event.status],
            'rounded-md px-2 py-1 text-sm  font-medium ring-1 ring-inset',
          ]"
          >{{ capitalize(unslugify(event.status)) }}</span
        >
      </div>
      <el-button circle plain type="primary" @click="edit = !edit">
        <el-icon :size="20">
          <Edit />
        </el-icon>
      </el-button>
    </div>
    <div v-if="edit">
      <hr class="my-2" />
      <div class="flex items-center justify-between">
        <div class="w-48">
          <el-select
            v-model="newStatus"
            filterable
            class="mt-1 w-12"
            placeholder="Select a status"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <el-checkbox v-model="sendEmailOnUpdate">Send email</el-checkbox>
      </div>
      <div class="mt-3">
        <el-button type="primary" @click="updateEventStatus" :loading="loading"
          >Update</el-button
        >
      </div>
    </div>
  </card>
</template>

<script>
import { Edit } from "@element-plus/icons-vue";

export default {
  components: {
    Edit,
  },
  props: ["event"],
  emits: ["refresh"],

  mounted() {
    this.newStatus = this.event.status;
  },
  data() {
    return {
      edit: false,
      loading: false,
      options: [
        { label: "Approved", value: "approved" },
        { label: "Under Review", value: "under_review" },
        { label: "Declined", value: "declined" },
        { label: "Closed", value: "closed" },
      ],
      colorEventStatusTags: {
        approved: "text-green-700 bg-green-50 ring-green-600/20",
        closed: "text-gray-600 bg-gray-50 ring-gray-500/10",
        declined: "text-red-700 bg-red-50 ring-red-600/10",
        under_review: "text-yellow-700 bg-yellow-50 ring-yellow-600/10",
      },
      newStatus: null,
      sendEmailOnUpdate: true,
    };
  },
  methods: {
    updateEventStatus() {
      this.loading = true;
      this.$axiosAuth
        .patch(`/kiosk/events/${this.event.event_id}/status/`, {
          status: this.newStatus,
          send_email: this.sendEmailOnUpdate,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Event status updated successfully",
            type: "success",
          });
          this.$emit("refresh");
        })
        .catch((err) => {
          this.loading = false;
          ElNotification({
            title: "Error",
            message: err.response.data,
            type: "error",
          });
        });
      this.loading = false;
      this.edit = false;
    },
  },
};
</script>
