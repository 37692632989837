<template>
  <card>
    <template #heading>
      <div class="flex items-center justify-between">
        Notes
        <el-button circle plain type="primary" @click="add = !add">
          <el-icon :size="20">
            <Plus />
          </el-icon>
        </el-button>
      </div>
    </template>
    <div class="space-y-4">
      <div v-if="add" class="space-y-3">
        <el-input v-model="noteMessage" type="textarea" clearable></el-input>
        <el-button type="primary" @click="createNote()" :loading="loading"
          >Save</el-button
        >
      </div>
      <hr v-if="add" />
      <div>
        <div v-if="event.notes.length" class="space-y-5">
          <div v-for="note in event.notes" :key="note.id" class="text-sm">
            <div class="rounded border border-gray-200 p-3">
              <p v-if="note.subject" class="font-semibold">
                {{ note.subject }}
              </p>
              <p v-if="note.message">{{ note.message }}</p>
            </div>
            <p class="mt-2 text-xs text-gray-500">
              Created by {{ note.author_name }}
              {{ this.$dayjs(note.created).fromNow() }}
            </p>
          </div>
        </div>
        <p v-else>No notes</p>
      </div>
    </div>
  </card>
</template>

<script>
import { Plus } from "@element-plus/icons-vue";

export default {
  props: ["event"],
  components: {
    Plus,
  },

  data() {
    return {
      loading: false,
      noteMessage: "",
      add: false,
    };
  },
  methods: {
    createNote() {
      this.loading = true;
      this.$axiosAuth
        .post(`/kiosk/events/${this.event.event_id}/note/`, {
          message: this.noteMessage,
        })
        .then((res) => {
          ElNotification({
            title: "Success",
            message: "Note created successfully",
            type: "success",
          });
          this.$emit("refresh");
        })
        .catch((err) => {
          this.loading = false;
          ElNotification({
            title: "Error",
            message: err.response.data,
            type: "error",
          });
        });
      this.loading = false;
      this.add = false;
    },
  },
};
</script>

<style>
</style>