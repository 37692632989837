<template>
  <div class="dashboard">
    <!-- loader div -->
    <div
      v-loading.fullscreen.lock="loading"
      element-loading-background="#ffffff"
    ></div>

    <AdminLayout v-if="!loading">
      <EventInReview v-if="event.status == 'under_review'" />
      <DashboardHeader v-else :event="event" @refreshEvent="getData()" />
      <div
        class="grid grid-cols-1 gap-6 px-6 py-8 lg:grid-cols-2 lg:gap-8 lg:px-40"
      >
        <div class="col-span-1 lg:col-span-2">
          <h2 class="mb-4 text-lg font-medium leading-6 text-gray-900">
            Overview
          </h2>
          <div class="grid grid-cols-1 gap-3 sm:grid-cols-4">
            <ContributionsCards
              v-if="event"
              :event="event"
              :contributions="contributions"
              :payouts="payouts"
              :dashboard="true"
              @refresh-data="getData()"
            />
          </div>
        </div>
        <div class="hidden lg:col-span-1 lg:block">
          <div class="mb-4 flex items-center justify-between">
            <h2 class="text-lg font-medium leading-6 text-gray-900">
              Registry Preview
            </h2>
          </div>
          <div class="group relative cursor-pointer rounded-lg bg-white">
            <div
              class="preview-buttons absolute left-1/2 top-1/2 z-50 flex -translate-x-1/2 -translate-y-1/2 transform gap-x-2 opacity-0 duration-300 group-hover:opacity-100"
            >
              <router-link :to="{ name: 'registry' }">
                <el-button type="primary" size="large" plain round
                  >Edit Registry</el-button
                >
              </router-link>
              <router-link :to="{ name: 'gifts' }">
                <el-button type="primary" size="large" plain round
                  >Add Gifts</el-button
                >
              </router-link>
            </div>
            <div class="duration-300 group-hover:opacity-20">
              <div class="hero">
                <SplitLayout
                  v-if="event.properties && event.properties.layout == 'split'"
                  :event="event"
                  :dashboardPreview="true"
                  class="rounded-t-lg"
                />
                <SplitLayout2
                  v-if="
                    event.properties && event.properties.layout == 'split 2'
                  "
                  :event="event"
                  :dashboardPreview="true"
                  class="rounded-t-lg"
                />
                <CenterLayout
                  v-if="event.properties && event.properties.layout == 'center'"
                  :event="event"
                  :dashboardPreview="true"
                  class="rounded-t-lg"
                />
                <FullLayout
                  v-if="event.properties && event.properties.layout == 'full'"
                  :event="event"
                  :dashboardPreview="true"
                  class="rounded-t-lg"
                />
              </div>
              <CustomFieldsPreview
                v-if="
                  event &&
                  event.properties &&
                  event.properties.showCustomFields &&
                  event.properties.layout != 'split'
                "
                :event="event"
                :dashboardPreview="true"
              />
              <GiftsPreview
                v-if="event"
                class="rounded-b-lg"
                :event="event"
                :dashboardPreview="true"
              />
            </div>
          </div>
        </div>
        <div class="col-span-1">
          <div class="mb-4 flex items-center justify-between">
            <h2 class="text-lg font-medium leading-6 text-gray-900">
              Recent Contributions
            </h2>
            <router-link :to="{ name: 'contributions' }">
              <el-button size="" type="primary" plain>See More</el-button>
            </router-link>
          </div>
          <ContributionsFeed
            v-if="event && contributions"
            :event="event"
            :contributions="contributions"
            :dashboard="true"
          />
        </div>
      </div>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from "@/layouts/Admin.vue";
import ContributionsFeed from "@/components/admin/ContributionsFeed.vue";
import DashboardHeader from "@/components/admin/DashboardHeader.vue";
import GiftsPreview from "../../components/public/GiftsPreview.vue";
import SplitLayout from "@/layouts/event/Split.vue";
import SplitLayout2 from "@/layouts/event/Split_2.vue";
import CenterLayout from "@/layouts/event/Center.vue";
import FullLayout from "@/layouts/event/Full.vue";
import ContributionsCards from "../../components/admin/ContributionsCards.vue";
import CustomFieldsPreview from "@/components/public/CustomFieldsPreview.vue";
import eventStyles from "@/mixins/eventStyles.js";
import { useUserStore } from "@/stores/UserStore";
import { mapActions } from "pinia";

export default {
  components: {
    AdminLayout,
    ContributionsFeed,
    DashboardHeader,
    GiftsPreview,
    SplitLayout,
    SplitLayout2,
    CenterLayout,
    FullLayout,
    ContributionsCards,
    CustomFieldsPreview,
  },

  data() {
    return {
      event: "",
      contributions: "",
      payouts: "",
      loading: false,
    };
  },

  mixins: [eventStyles],

  mounted() {
    this.loading = true;
    this.getData();
  },

  computed: {
    divider() {
      if (this.event) {
        let divider = this.dividers.find(
          (d) => d.name == this.event.properties.divider
        );
        return divider.divider.preview;
      }
    },
  },

  methods: {
    ...mapActions(useUserStore, [
      "updateEmailBounced",
      "updateEmailVerified",
      "updateUserCountryCode",
    ]),
    getData() {
      Promise.all([
        this.$axiosAuth.get(`host/event/`),
        this.$axiosAuth.get(`host/contribution/`),
        this.$axiosAuth.get(`host/stripe/balance`),
      ])
        .then((res) => {
          this.event = res[0].data[0];
          this.contributions = res[1].data;
          this.payouts = res[2].data;

          this.updateEmailBounced(this.event.email_bounced);
          this.updateEmailVerified(this.event.email_verified);
        })
        .then(() => {
          this.loading = false;
          //@todo temporary code for amazon gift feature, as we need the country code in pinia.
          const userStore = useUserStore();
          const user = userStore.user;
          if (!user.country_code) {
            this.updateUserCountryCode(this.event.country_code);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.status !== 401) {
            ElNotification({
              title: "Error",
              message: "Something went wrong, contact support for help.",
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style>
.dashboard {
  .hero {
    mask: v-bind(divider);
    clip-path: v-bind(divider);
  }
  .preview-buttons button {
    @apply text-xl !important;
  }
}
</style>
